:root {
  --drawer-top: 50px;
}

.manifest-details-portal > :first-child {
  top: var(--drawer-top);
  z-index: 17;
}

.all-assigned-stops-portal > :first-child {
  top: var(--drawer-top);
  z-index: 18;
}

.chat-portal {
  z-index: 19;
}

.global-find-portal {
  z-index: 20;
}

.settings-portal {
  z-index: 20;
}

.top-left-toast {
  z-index: 21;
}

.spaces-resize-handle {
  background: linear-gradient(270deg, #ededed 0%, #0e0e0e 50%, #ededed 100%) !important;
  width: 3px !important;
  transition: width 0.25s;
  height: 100%;
}
.spaces-resize-handle.fixed {
  background: linear-gradient(270deg, #ededed 0%, #0e0e0e 50%, #ededed 100%) !important;
  width: 3px !important;
  height: 100%;
  transition: width 0.25s;
}
.spaces-resize-handle:hover {
  width: 6px !important;
}
.unassigned-jobs-view .spaces-resize-handle:before {
  cursor: col-resize;
}
.reflex-splitter {
  border: none !important;
}

.map-action-bar-popover {
  box-shadow: none;
}

.map-action-bar-popover .bp4-popover2-content {
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  margin-top: 2px;
  box-shadow: 0px 0px 5px 0px #00000040;
}

.map-action-bar-popover .bp4-popover2-content .bp4-popover2-target {
  width: 100%;
}

.map-action-popover {
  box-shadow: 0px 0px 5px 0px #00000040;
  max-width: 150px;
  border-radius: 5px;
}

.map-action-popover .bp4-popover2-content {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  color: #161616;
  background-color: white;
}

.map-action-popover .bp4-popover2-arrow-fill {
  fill: #ffffff !important;
}

.driver-status-popover {
  font-size: 12px !important;
}

.driver-status-popover .bp4-popover2-content {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}

.drawer_container {
  height: calc(100% - var(--drawer-top)) !important;
  top: var(--drawer-top) !important;
}

.multiple-stops-popover {
  box-shadow: 0px 4px 4px 0px #0000001a;
  border-radius: 22px;
  background-color: #ffffffde !important;
}

.multiple-stops-popover .bp4-popover2-content {
  background-color: transparent !important;
  max-height: 45vh;
  overflow: auto;
}

.multiple-stops-popover .bp4-popover2-arrow::before {
  display: none;
}

.multiple-stops-popover .bp4-popover2-arrow-fill {
  fill: #ffffffde !important;
}

.job-number-tooltip-popover {
  box-shadow: 0px 0px 5px 0px #00000040;
  border-radius: 4px;
}

.job-number-tooltip-popover .bp4-popover2-content {
  background-color: #ffffff !important;
  border-radius: 4px;
}

.job-number-tooltip-popover .bp4-popover2-arrow-fill {
  fill: #ffffff !important;
}

.white-popover {
  box-shadow: 0px 0px 5px 0px #00000040;
  border-radius: 4px;
  background-color: #ffffff !important;
}

.white-popover .bp4-popover2-content {
  padding: 6px;
  overflow: hidden;
  background-color: #ffffff !important;
  border-radius: 4px;
  color: #161616;
}

.white-popover .bp4-popover2-arrow-fill {
  fill: #ffffff !important;
}

.ag-header-cell.ag-header-cell-sortable.ag-focus-managed[col-id="indicatorColor"] .ag-sort-indicator-icon {
  transform: translateX(-6px);
}
