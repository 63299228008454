.manifest-stop-details-popover {
  background: #d9d9d9;
  border-radius: 4px;
}

.manifest-details-action-dialog > div.bp4-dialog-header {
  box-shadow: 0 0 0 rgba(255, 255, 255, 0);
  background: transparent;
}

.manifest-details-action-dialog > div.bp4-dialog-header > button.bp4-dialog-close-button {
  align-self: flex-start;
}
.manifest-job-color-tooltip {
  box-shadow: 0px 0px 3px 0px #00000040;
  border-radius: 4px;
}

.manifest-job-color-tooltip .bp4-popover2-content {
  font-family: "Roboto", sans-serif;
  background: #fde9df;
  color: #161616;
  font-weight: 400;
  border-radius: 4px;
  font-size: 12px;
  padding: 10px 15px;
  width: 287px;
  box-shadow: none;
  border: none;
}

.manifest-job-color-tooltip .bp4-popover2-arrow-fill {
  fill: #fde9df !important;
  border: none;
}

.mark-complete-stop-time-popover .bp4-popover2-content {
  max-width: 200px;
  font-size: 12px;
}

.stop-location-dropdown {
  transform: translateY(4px) !important;
  box-shadow: none !important;
}

.places-auto-popover {
  box-shadow: none !important;
}

.stop-location-dropdown .bp4-popover2-content,
.places-auto-popover .bp4-popover2-content {
  box-shadow: 0px 4px 4px 0px #00000040 !important;
  border-radius: 4px;
}

.stop-location-dropdown .bp4-input {
  border-radius: 4px;
  border: 1px solid #cbd0df;
  box-shadow: none;
}

.menu-title .ag-menu-option-text {
  font-size: 12px;
  padding: 0 0 6px;
  transform: translateX(-4px);
}

.ag-cell-focus {
  border: none !important;
}

.manifest-status-popover .bp4-popover2-content {
  width: 150px;
  padding: 8px 4px;
}

.manifest-status-popover.minimal .bp4-popover2-content {
  width: 120px;
}

.manifest-status-popover .bp4-menu {
  min-width: unset;
}

.optimized-status-popover .bp4-popover2-content {
  max-width: 278px;
  padding: 8px;
}
